import { action, computed, observable } from "mobx";
import { isEmptyOrWhitespace, ModelBase } from "@shoothill/core";

import { AddressDTO } from "./Supporting/AddressModel";
import { CategoryDTO } from "./Supporting/CategoryModel";
import { IncomeAndExpenditureDTO } from "./Supporting/IncomeAndExpenditureModel";
import { LineDescriptionDTO } from "./Supporting/LineDescriptionModel";
import { OrderTypeDTO } from "./Supporting/OrderTypeModel";
import { PaymentTermsDTO } from "./Supporting/PaymentTermsModel";
import { PaymentTypeDTO } from "./Supporting/PaymentTypeModel";
import { ProjectDTO } from "./Supporting/ProjectModel";
import { SubCategoryDTO } from "./Supporting/SubCategoryModel";
import { SupplierDTO } from "./Supporting/SupplierModel";
import { TermsAndConditionsDTO } from "./Supporting/TermsAndConditionsModel";
import {
    RequisitionRequestItemGroupModel,
    RequisitionRequestItemGroupRequestDTO,
    RequisitionRequestItemGroupResponseDTO,
} from "./Supporting/OrderLine/Tables/RequisitionRequestItemGroupModel";
import { SupplierDetailsDTO } from "./Supporting/SupplierDetailsModel";
import { StockTypeDTO } from "./Supporting/StockTypeModel";
import { VariationDTO } from "./Supporting/VariationModel";
import { RequisitionPOStatus } from "Views/Approval/PurchaseListViewModel";
import { PurchaseOrderApprovalPanelDTO } from "./PurchaseOrderApprovalPanelModel";
import { RequisitionRequestItemModel } from "./Supporting/OrderLine/Tables/RequisitionRequestItemModel";
import { ApprovalHistoryItemDTO } from "Globals/ViewModels/ApprovalPanelViewModelBase";
import { ApprovalHistoryDTO } from "Components/ApprovalHistory/ApprovalHistoryDTO";
import { POTypeDTO } from "./Supporting/POTypeModel";

export class PurchaseOrderModel extends ModelBase<PurchaseOrderModel, any> {
    // #region Constructors and Disposers
    // #endregion Constructors and Disposers

    // #region Constants and Defaults

    public static readonly DEFAULT_ID = null;
    public static readonly DEFAULT_INCOMEANDEXPENDITUREID = null;
    public static readonly DEFAULT_ORDERTYPEID = null;
    public static readonly DEFAULT_PROJECTID = null;
    public static readonly DEFAULT_VARIATIONID = null;
    public static readonly DEFAULT_SUPPLIERID = null;
    public static readonly DEFAULT_NOTE = "";

    public static readonly DEFAULT_DATEREQUIRED = null;
    public static readonly DEFAULT_DATEFROM = null;
    public static readonly DEFAULT_DATETO = null;
    public static readonly DEFAULT_WEEKCOMMENCING = null;
    public static readonly DEFAULT_DESCRIPTION = "";

    public static readonly DEFAULT_DELIVERYADDRESSID = null;
    public static readonly DEFAULT_DELIVERYCONTACTNAME = "";
    public static readonly DEFAULT_DELIVERYCONTACTMOBILE: "";
    public static readonly DEFAULT_DELIVERYCOSTS = null;

    public static readonly DEFAULT_TERMSANDCONDITIONSID = null;
    public static readonly DEFAULT_PAYMENTTERMSID = null;
    public static readonly DEFAULT_PAYMENTTYPEID = null;
    public static readonly DEFAULT_PAYMENTTERMSINDAYS = 0;

    public static readonly DEFAULT_REQUISITIONGROUPS = [];
    public static readonly DEFAULT_REQUESTERNOTE = "";

    public static readonly DEFAULT_ISSTOCK = false;
    public static readonly DEFAULT_ISCENTRAL = false;
    public static readonly DEFAULT_STOCKTYPEID = null;

    public static readonly DEFAULT_ISPO = false;
    public static readonly DEFAULT_SENDEMAILTOSUPPLIER = true;
    public static readonly DEFAULT_ADDITIONALCONTACTEMAILADDRESS = null;
    public static readonly DEFAULT_SUPPLIERCONTACTEMAILS = null;
    public static readonly DEFAULT_ISPREPAYMENT = false;
    public static readonly DEFAULT_PREPAYMENTPARENTREQUISITIONREQUESTID = null;
    public static readonly DEFAULT_ISFINALPREPAYMENT = false;
    public static readonly DEFAULT_PREPAYMENTPARENTPONUMBER = null;
    public static readonly DEFAULT_ISSUBCONTRACTORAGREEMENT = false;
    public static readonly DEFAULT_ISDUPLICATESUPPLIER = false;
    public static readonly DEFAULT_PONUMBERFORMATTED = null;

    // #endregion Constants and Defaults

    // #region Properties

    @observable
    public id: string | null = PurchaseOrderModel.DEFAULT_ID;

    @observable
    public ieId: string | null = PurchaseOrderModel.DEFAULT_INCOMEANDEXPENDITUREID;

    // NOTE this uses a hardwired enum. Would prefer this came from the database as a known type.

    @observable
    public orderTypeId: string | null = PurchaseOrderModel.DEFAULT_ORDERTYPEID;

    // @observable
    // public variationId: string | null = PurchaseOrderModel.DEFAULT_PROJECTID;

    @observable
    public projectId: string | null = PurchaseOrderModel.DEFAULT_PROJECTID;

    @observable
    public supplierId: string | null = PurchaseOrderModel.DEFAULT_SUPPLIERID;

    @observable
    public note: string = PurchaseOrderModel.DEFAULT_NOTE;

    @observable
    public requisitionNote: string = PurchaseOrderModel.DEFAULT_NOTE;

    @observable
    public dateRequired: string | null = PurchaseOrderModel.DEFAULT_DATEREQUIRED;

    @observable
    public dateFrom: string | null = PurchaseOrderModel.DEFAULT_DATEFROM;

    @observable
    public dateTo: string | null = PurchaseOrderModel.DEFAULT_DATETO;

    @observable
    public weekCommencing: string | null = PurchaseOrderModel.DEFAULT_WEEKCOMMENCING;

    @observable
    public description: string = PurchaseOrderModel.DEFAULT_DESCRIPTION;

    @observable
    public deliveryAddressId: string | null = PurchaseOrderModel.DEFAULT_DELIVERYADDRESSID;

    @observable
    public deliveryContactName: string = PurchaseOrderModel.DEFAULT_DELIVERYCONTACTNAME;

    @observable
    public deliveryContactMobile: string = PurchaseOrderModel.DEFAULT_DELIVERYCONTACTMOBILE;

    @observable
    public deliveryCosts: number | null = PurchaseOrderModel.DEFAULT_DELIVERYCOSTS;

    @observable
    public paymentTermsInDays: number = PurchaseOrderModel.DEFAULT_PAYMENTTERMSINDAYS;

    @observable
    public termsAndConditionsId: string | null = PurchaseOrderModel.DEFAULT_TERMSANDCONDITIONSID;

    @observable
    public paymentTermsId: string | null = PurchaseOrderModel.DEFAULT_PAYMENTTERMSID;

    @observable
    public paymentTypeId: string | null = PurchaseOrderModel.DEFAULT_PAYMENTTYPEID;

    @observable
    public requisitionGroups = observable<RequisitionRequestItemGroupModel>(PurchaseOrderModel.DEFAULT_REQUISITIONGROUPS);

    @observable
    public requesterNotes: string = PurchaseOrderModel.DEFAULT_REQUESTERNOTE;

    @observable
    public requisitionFile: IDisplayFile[] = [];

    @observable
    public purchaseFile: IDisplayFile[] = [];

    @observable
    public termsAndConditionsFileName: string | undefined = undefined;

    @observable
    public termsAndConditionsFileUrl: string | undefined = undefined;

    @observable
    public revision: string | null = null;

    @observable
    public lastRevisionedByUserId: string | undefined = undefined;

    @observable
    public isStock: boolean = PurchaseOrderModel.DEFAULT_ISSTOCK;

    @observable
    public stockTypeId: string | null = PurchaseOrderModel.DEFAULT_STOCKTYPEID;

    @observable
    public isCentral: boolean = PurchaseOrderModel.DEFAULT_ISCENTRAL;

    @observable
    public isPO: boolean = false;

    @observable
    public requisitionPOStatusId: string | undefined = undefined;

    @observable
    public sendEmailToSupplier: boolean = PurchaseOrderModel.DEFAULT_SENDEMAILTOSUPPLIER;

    @observable
    public additionalContactEmailAddress: string | null = PurchaseOrderModel.DEFAULT_ADDITIONALCONTACTEMAILADDRESS;

    @observable
    public supplierContactEmails: string | null = PurchaseOrderModel.DEFAULT_SUPPLIERCONTACTEMAILS;

    @observable
    public isPrePayment: boolean = PurchaseOrderModel.DEFAULT_ISPREPAYMENT;

    @observable
    public prePaymentParentRequisitionRequestId: string | null = PurchaseOrderModel.DEFAULT_PREPAYMENTPARENTREQUISITIONREQUESTID;

    @observable
    public isFinalPrePayment: boolean = PurchaseOrderModel.DEFAULT_ISFINALPREPAYMENT;

    @observable
    public prePaymentParentPONumber: number | null = PurchaseOrderModel.DEFAULT_PREPAYMENTPARENTPONUMBER;

    @observable
    public latestSupplierPOContactEmails: string | null = null;

    @observable
    public isSubcontractorAgreement: boolean = PurchaseOrderModel.DEFAULT_ISSUBCONTRACTORAGREEMENT;

    @observable
    public isDuplicateSupplier: boolean = PurchaseOrderModel.DEFAULT_ISDUPLICATESUPPLIER;

    @observable
    public poNumberFormatted: string | null = PurchaseOrderModel.DEFAULT_PONUMBERFORMATTED;

    @observable
    public prePaymentCategoryNames: string[] = [];

    @observable
    public prePaymentSubCategoryNames: string[] = [];

    @observable
    public prePaymentDescriptionNames: string[] = [];

    @observable
    public prePaymentDeliveryAddress: string | null = null;

    // #endregion Properties

    // #region Actions

    @action
    public reset = () => {
        this.id = PurchaseOrderModel.DEFAULT_ID;

        this.isStock = PurchaseOrderModel.DEFAULT_ISSTOCK; // Yes / No
        this.stockTypeId = PurchaseOrderModel.DEFAULT_STOCKTYPEID; // To stock / From stock
        this.isCentral = PurchaseOrderModel.DEFAULT_ISCENTRAL; // Central / Project

        this.projectId = PurchaseOrderModel.DEFAULT_PROJECTID; // Project (inc Stock)
        this.ieId = PurchaseOrderModel.DEFAULT_INCOMEANDEXPENDITUREID; // I & E (inc Stock)
        this.orderTypeId = PurchaseOrderModel.DEFAULT_ORDERTYPEID; // Sale / Hire

        this.dateFrom = PurchaseOrderModel.DEFAULT_DATEFROM; // Order type === Hire
        this.dateTo = PurchaseOrderModel.DEFAULT_DATETO; // Order rtpe === Hire
        this.dateRequired = PurchaseOrderModel.DEFAULT_DATEREQUIRED; // Order type == Purchase
        this.weekCommencing = PurchaseOrderModel.DEFAULT_WEEKCOMMENCING; // Order type === Labour

        this.description = PurchaseOrderModel.DEFAULT_DESCRIPTION; // Purchase order description

        this.supplierId = PurchaseOrderModel.DEFAULT_SUPPLIERID;
        this.additionalContactEmailAddress = PurchaseOrderModel.DEFAULT_ADDITIONALCONTACTEMAILADDRESS;
        this.supplierContactEmails = PurchaseOrderModel.DEFAULT_SUPPLIERCONTACTEMAILS;
        this.termsAndConditionsId = PurchaseOrderModel.DEFAULT_TERMSANDCONDITIONSID;
        this.termsAndConditionsFileName = undefined;
        this.termsAndConditionsFileUrl = undefined;
        this.isSubcontractorAgreement = PurchaseOrderModel.DEFAULT_ISSUBCONTRACTORAGREEMENT;
        this.isDuplicateSupplier = PurchaseOrderModel.DEFAULT_ISDUPLICATESUPPLIER;
        this.paymentTermsInDays = PurchaseOrderModel.DEFAULT_PAYMENTTERMSINDAYS;
        this.paymentTermsId = PurchaseOrderModel.DEFAULT_PAYMENTTERMSID;
        this.paymentTypeId = PurchaseOrderModel.DEFAULT_PAYMENTTYPEID;
        this.sendEmailToSupplier = PurchaseOrderModel.DEFAULT_SENDEMAILTOSUPPLIER;

        this.isPrePayment = PurchaseOrderModel.DEFAULT_ISPREPAYMENT;
        this.prePaymentParentRequisitionRequestId = PurchaseOrderModel.DEFAULT_PREPAYMENTPARENTREQUISITIONREQUESTID;
        this.isFinalPrePayment = PurchaseOrderModel.DEFAULT_ISFINALPREPAYMENT;
        this.prePaymentParentPONumber = PurchaseOrderModel.DEFAULT_PREPAYMENTPARENTPONUMBER;

        this.deliveryAddressId = PurchaseOrderModel.DEFAULT_DELIVERYADDRESSID;
        this.deliveryContactName = PurchaseOrderModel.DEFAULT_DELIVERYCONTACTNAME;
        this.deliveryContactMobile = PurchaseOrderModel.DEFAULT_DELIVERYCONTACTMOBILE;
        this.deliveryCosts = PurchaseOrderModel.DEFAULT_DELIVERYCOSTS;

        this.note = PurchaseOrderModel.DEFAULT_NOTE; // Add requisition note
        this.requisitionNote = PurchaseOrderModel.DEFAULT_NOTE; // Add requisition note

        this.requesterNotes = PurchaseOrderModel.DEFAULT_REQUESTERNOTE;
        this.requisitionGroups.replace(PurchaseOrderModel.DEFAULT_REQUISITIONGROUPS);
        this.requisitionFile = [];
        this.purchaseFile = [];
        this.revision = null;
        this.lastRevisionedByUserId = undefined;
        this.isPO = PurchaseOrderModel.DEFAULT_ISPO;
        this.requisitionPOStatusId = undefined;

        this.poNumberFormatted = PurchaseOrderModel.DEFAULT_PONUMBERFORMATTED;

        this.prePaymentCategoryNames = [];
        this.prePaymentSubCategoryNames = [];
        this.prePaymentDescriptionNames = [];
    };

    @action
    public fromDto(dto: PurchaseOrderUpsertResponseDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in dto) {
            if (dto.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(dto[key]);
                } else if (key === "requisitionGroups") {
                    this.requisitionGroups.replace(RequisitionRequestItemGroupModel.fromDtos(dto[key]));
                } else {
                    this[key] = dto[key];
                }
            }
        }
    }

    public toDto(): PurchaseOrderUpsertRequestDTO {
        let requisitionDocumentsToUpsert: IDisplayFile[] = [];
        let purchaseDocumentsToUpsert: IDisplayFile[] = [];
        let groupsToUpsert: RequisitionRequestItemGroupRequestDTO[] = [];

        // Don't send a file to the server if it doesn't exist in the database and has been deleted from the client.
        // Avoids populating the datbase with deleted data.
        this.requisitionFile.forEach((file) => {
            if (!file.isDeleted || !isEmptyOrWhitespace(file.id)) {
                requisitionDocumentsToUpsert.push(file);
            }
        });

        this.purchaseFile.forEach((file) => {
            if (!file.isDeleted || !isEmptyOrWhitespace(file.id)) {
                purchaseDocumentsToUpsert.push(file);
            }
        });

        // Only upsert a group if it already exists in the database, or if it has items.
        this.requisitionGroups.forEach((group) => {
            let items: RequisitionRequestItemModel[] = group.requisitionRequestItems.toJS();
            let groupItemsToUpsert: RequisitionRequestItemModel[] = [];

            // Only upsert an item if it already exists in the database, or isn't deleted.
            items.forEach((item) => {
                if (!item.isDeleted || !isEmptyOrWhitespace(item.id)) {
                    groupItemsToUpsert.push(item);
                }
            });

            let groupToUpsert: RequisitionRequestItemGroupRequestDTO = {
                id: group.id,
                rowVersion: group.rowVersion,
                newFutureSpendId: group.newFutureSpendId,
                newFutureSpend: group.newFutureSpend,
                note: group.note,
                lineDescriptionId: group.lineDescriptionId,
                lineDescriptionDisplayName: group.lineDescriptionDisplayName,
                subCategoryId: group.subCategoryId,
                subCategoryDisplayName: group.subCategoryDisplayName,
                categoryId: group.categoryId,
                categoryDisplayName: group.categoryDisplayName,
                isWithinSpendAllowance: group.isWithinSpendAllowance,
                subType: group.subType,
                requisitionRequestItems: groupItemsToUpsert,
                variationId: group.variationId,
                isDeleted: group.isDeleted,
            };

            if (!isEmptyOrWhitespace(groupToUpsert.id) || groupToUpsert.requisitionRequestItems.length > 0) {
                groupsToUpsert.push(groupToUpsert);
            }
        });

        return {
            id: this.id,
            ieId: this.ieId,
            orderTypeId: this.orderTypeId,
            projectId: this.projectId,
            supplierId: this.supplierId,
            note: this.note,
            requisitionNote: this.requisitionNote,
            dateRequired: this.dateRequired,
            dateFrom: this.dateFrom,
            dateTo: this.dateTo,
            weekCommencing: this.weekCommencing,
            description: this.description,
            deliveryAddressId: this.deliveryAddressId,
            deliveryContactName: this.deliveryContactName,
            deliveryContactMobile: this.deliveryContactMobile,
            deliveryCosts: this.deliveryCosts,
            paymentTermsInDays: this.paymentTermsInDays,
            termsAndConditionsId: this.termsAndConditionsId,
            paymentTermsId: this.paymentTermsId,
            paymentTypeId: this.paymentTypeId,
            requesterNotes: this.requesterNotes,
            requisitionGroups: groupsToUpsert,
            requisitionFile: requisitionDocumentsToUpsert,
            purchaseFile: purchaseDocumentsToUpsert,
            termsAndConditionsFileName: this.termsAndConditionsFileName,
            termsAndConditionsFileUrl: this.termsAndConditionsFileUrl,
            revision: this.revision,
            lastRevisionedByUserId: this.lastRevisionedByUserId,
            isStock: this.isStock !== null ? this.isStock : false,
            isCentral: this.isCentral !== null ? this.isCentral : false,
            stockTypeId: this.stockTypeId,
            sendEmailToSupplier: this.sendEmailToSupplier,
            additionalContactEmailAddress: this.additionalContactEmailAddress,
            supplierContactEmails: this.supplierContactEmails,
            isPrePayment: this.isPrePayment,
            prePaymentParentRequisitionRequestId: this.prePaymentParentRequisitionRequestId,
            isFinalPrePayment: this.isFinalPrePayment,
            prePaymentParentPONumber: this.prePaymentParentPONumber,
            isSubcontractorAgreement: this.isSubcontractorAgreement,
            isDuplicateSupplier: this.isDuplicateSupplier,
            saveWithoutValidation: false, // Set in viewmodel.
        };
    }

    // #endregion Actions

    // #region Custom Validation

    @computed
    public get validateIsStock(): string {
        // RULES
        // Must be selected.
        return this.isStock === null || this.isStock === undefined ? "Please select if is this a stock requisition" : "";
    }

    @computed
    public get validateStockTypeId(): string {
        // RULES
        // The stock type must be defined if the request is a stock requisition.
        return this.isStock === true && this.stockTypeId === PurchaseOrderModel.DEFAULT_STOCKTYPEID ? "Please select a stock type" : "";
    }

    //

    @computed
    public get validatePrePaymentParentRequisitionRequestId(): string {
        // RULES
        // The pre payment PO must be defined if the request is a final pre payment.
        return this.isFinalPrePayment === true && this.prePaymentParentRequisitionRequestId === PurchaseOrderModel.DEFAULT_PREPAYMENTPARENTREQUISITIONREQUESTID
            ? "Please select a pre payment PO"
            : "";
    }

    @computed
    public get validateDescription(): string {
        // RULES
        // The description must not be null or whitespace.
        return isEmptyOrWhitespace(this.description) ? "Please provide a description" : "";
    }

    @computed
    public get validateIncomeAndExpenditureId(): string {
        // RULES
        // An income and expenditure must be defined.
        return this.ieId === PurchaseOrderModel.DEFAULT_INCOMEANDEXPENDITUREID ? "Please select an income and expenditure" : "";
    }

    @computed
    public get validateOrderId(): string {
        // RULES
        // An order type must be defined.
        return this.orderTypeId === PurchaseOrderModel.DEFAULT_ORDERTYPEID || this.orderTypeId === "00000000-0000-0000-0000-000000000000" || this.orderTypeId === ""
            ? "Please select an order type"
            : "";
    }

    @computed
    public get validateDeliveryAddressId(): string {
        // RULES
        // A delivery address must be defined.
        return this.deliveryAddressId === PurchaseOrderModel.DEFAULT_DELIVERYADDRESSID ? "Please select an address" : "";
    }

    @computed
    public get validateDeliveryContactName(): string {
        // RULES
        // The delivery contact name must not be null or whitespace.
        return isEmptyOrWhitespace(this.deliveryContactName) ? "Please provide a name" : "";
    }

    @computed
    public get validateDeliveryContactMobile(): string {
        // RULES
        // The delivery contact number must not be null or whitespace.
        return isEmptyOrWhitespace(this.deliveryContactMobile) ? "Please provide a number" : "";
    }

    // #endregion Custom Validation

    // #region Business Logic

    @computed
    public get total() {
        return this.requisitionGroups.reduce((acc, m) => {
            return parseFloat((acc + m.total).toFixed(2));
        }, 0);
    }

    @computed
    public get postCommittedCost() {
        return this.requisitionGroups.reduce((acc, m) => {
            return parseFloat((acc + m.postCommittedCost).toFixed(2));
        }, 0);
    }

    // #endregion Business Logic
}

export interface PurchaseOrderUpsertRequestDTO {
    id: string | null;
    ieId: string | null;
    orderTypeId: string | null;
    projectId: string | null;
    supplierId: string | null;
    note: string;
    requisitionNote: string;
    dateRequired: string | null;
    dateFrom: string | null;
    dateTo: string | null;
    weekCommencing: string | null;
    description: string;
    deliveryAddressId: string | null;
    deliveryContactName: string;
    deliveryContactMobile: string;
    deliveryCosts: number | null;
    paymentTermsInDays: number;
    termsAndConditionsId: string | null;
    paymentTermsId: string | null;
    paymentTypeId: string | null;
    requesterNotes: string;
    requisitionGroups: RequisitionRequestItemGroupRequestDTO[];
    requisitionFile: IDisplayFile[];
    purchaseFile: IDisplayFile[];
    termsAndConditionsFileName: string | undefined;
    termsAndConditionsFileUrl: string | undefined;
    revision: string | null;
    lastRevisionedByUserId: string | undefined;
    isStock: boolean;
    isCentral: boolean;
    stockTypeId: string | null;
    sendEmailToSupplier: boolean;
    additionalContactEmailAddress: string | null;
    supplierContactEmails: string | null;
    isPrePayment: boolean;
    prePaymentParentRequisitionRequestId: string | null;
    isFinalPrePayment: boolean;
    prePaymentParentPONumber: number | null;
    isSubcontractorAgreement: boolean;
    isDuplicateSupplier: boolean;
    saveWithoutValidation: boolean;
}

export interface PurchaseOrderUpsertResponseDTO {
    id: string | null;
    ieId: string | null;
    orderTypeId: string | null;
    projectId: string | null;
    variationId: string | null;
    supplierId: string | null;
    note: string | null;
    requisitionNote: string | null;
    dateRequired: string | null;
    dateFrom: string | null;
    dateTo: string | null;
    weekCommencing: string | null;
    description: string;
    deliveryAddressId: string | null;
    deliveryContactName: string;
    deliveryContactMobile: string;
    deliveryCosts: number | null;
    termsAndConditionsId: string | null;
    paymentTermsId: string | null;
    paymentTypeId: string | null;
    requesterNotes: string;
    paymentTermsInDays: number;
    requisitionGroups: RequisitionRequestItemGroupResponseDTO[];
    isPO: boolean;
    poNumber: number | null;
    termsAndConditionsFileName: string | undefined;
    termsAndConditionsFileUrl: string | undefined;
    sendEmailToSupplier: boolean;
    additionalContactEmailAddress: string | null;
    supplierContactEmails: string | null;
    isPrePayment: boolean;
    prePaymentParentRequisitionRequestId: string | null;
    isFinalPrePayment: boolean;
    prePaymentParentPONumber: number | null;
    isSubcontractorAgreement: boolean;
    isDuplicateSupplier: boolean;
    poNumberFormatted: string | null;
    latestSupplierPOContactEmails: string | null;
    requisitionPOStatusId: string | null;
    invalidItems: ProcessedRequisitionRequestInvoiceItem[];
    matchingUnapprovedRequisitions: { id: string; name: string; requestedByUserName: string }[];
}

export interface PurchaseOrderRelatedResponseDTO {
    addresses: AddressDTO[];
    orderTypes: OrderTypeDTO[];
    paymentTerms: PaymentTermsDTO[];
    paymentTypes: PaymentTypeDTO[];
    projects: ProjectDTO[];
    suppliers: SupplierDTO[];
    termsAndConditions: TermsAndConditionsDTO[];
    supplierDetails: SupplierDetailsDTO;
    ie: IncomeAndExpenditureDTO[];
    ieCategory: CategoryDTO[];
    ieSubcategory: SubCategoryDTO[];
    ieLineItem: LineDescriptionDTO[];
    stockTypes: StockTypeDTO[];
    variations: VariationDTO[];
    requisitionPOStatuses: RequisitionPOStatus[];
    requisitionDocuments: IDisplayFile[];
    purchaseDocuments: IDisplayFile[];
    centralPrePaymentPOOptions: POTypeDTO[];
}

export interface ProcessedRequisitionRequestInvoiceItem {
    id: string | null;
    amountAllocatedTotal: number;
    subTotal: number;
    isValid: boolean;
    difference: number;
    categoryDisplayName: string;
    subCategoryDisplayName: string;
    lineDescriptionDisplayName: string;
    lineDisplayName: string;
    itemDescription: string;
}

export interface PurchaseOrderAndRelatedResponseDTO extends PurchaseOrderRelatedResponseDTO {
    requisitionDetail: PurchaseOrderUpsertResponseDTO;
    approvalPanel: PurchaseOrderApprovalPanelDTO | null;
    approvalHistoryItems: ApprovalHistoryItemDTO[];
    approvalHistory: ApprovalHistoryDTO[];
    prePaymentCategoryNames: string[];
    prePaymentSubCategoryNames: string[];
    prePaymentDescriptionNames: string[];
    revisionHistory: { formattedPONumber: string; revision: string }[];
    isDuplicateSupplier: boolean;
}

export interface IDisplayFile {
    id: string | null;
    fileName: string;
    fileUrl: string;
    isDeleted: boolean;
    rowVersion: string | null;
    originatorId: string | null;
    noteTypeId: string | null;
    requisitionRequestId: string | null;
}

export interface BudgetForecast {
    preTargetCost: number;
    preCommittedCost: number;
    preFutureSpend: number;
    preTotalExpectedSpend: number;
    preVariance: number;
    postTargetCost: number;
    postCommittedCost: number;
    postFutureSpend: number;
    postTotalExpectedSpend: number;
    postVariance: number;
}

export interface BudgetForecastFormatted {
    preTargetCost: string;
    preCommittedCost: string;
    preFutureSpend: string;
    preTotalExpectedSpend: string;
    preVariance: string;
    postTargetCost: string;
    postCommittedCost: string;
    postFutureSpend: string;
    postTotalExpectedSpend: string;
    postVariance: string;
}
